import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PyramidChart from "pages/Common/Charts/Pyramid";
import FagsAccordions from "pages/Common/Accordion/Index";
import MKBadge from "components/MKBadge";

function SuccessPath() {
  return (
    <MKBox component="section" pt={1} my={2}>
      <Grid
        container
        item
        xs={12}
        lg={6}
        flexDirection="column"
        alignItems="center"
        sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
      >
        <MKBadge
          variant="contained"
          color="info"
          badgeContent="Get Your Answers"
          container
          sx={{ mb: 2 }}
        />
        <MKTypography variant="h2" fontWeight="bold">
          Frequently Asked Questions
        </MKTypography>
        <MKTypography variant="body1" color="text">
          The easiest way to get answers
          <br /> If still have question then <a href='/contact-us'>Contact Us</a>.
        </MKTypography>
      </Grid>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <FagsAccordions />
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <div className="container mx-auto mt-10" >
              <PyramidChart
                categories={['Victory', 'Stay Patient', 'Stay Committed', 'Stay Updated', 'Seek Feedback', 'Play Regularly', 'Develop a Study Plan', 'Work on Endgames', 'Analyze Master Games', 'Study Opening Principles', 'Master Basic Tactics', 'Learn the Rules']}
                name={'Graph Rate'}
                data={[
                  500,
                  600,
                  700,
                  900,
                  1100,
                  1200,
                  1300,
                  1400,
                  1500,
                  1620,
                  1700,
                  1800]}
                text={'Create Your Future'}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SuccessPath;