import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

import '../../assets/css/carousel.css';
import imc1 from "assets/images/studs/imc1.jpg";
import imc5 from "assets/images/studs/imc5.jpg";
import imc6 from "assets/images/studs/imc6.jpg";
import imc8 from "assets/images/studs/imc8.jpg";
import imc13 from "assets/images/studs/imc13.jpg";
import imc14 from "assets/images/studs/imc14.jpg";
import imc15 from "assets/images/studs/imc15.jpg";
import imc16 from "assets/images/studs/imc16.jpg";
import imc19 from "assets/images/studs/imc19.jpg";
import imc20 from "assets/images/studs/imc20.jpg";

export default function ImageCarousel() {
    return (
        <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            loop={true}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }}
            // pagination={true}
            modules={[EffectCoverflow, Autoplay]}
            className="mySwiper"
            initialSlide={2} // Start from the third image (index 2)
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
        >
            <SwiperSlide>
                <img src={imc1} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc5} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc6} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc8} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc13} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc14} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc15} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc16} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc19} />
            </SwiperSlide>
            <SwiperSlide>
                <img src={imc20} />
            </SwiperSlide>
        </Swiper>
    );
}
