import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Autoplay, EffectCube, Pagination } from 'swiper/modules';

export default function CubeSwipper({ images = [] }) {
  return (
    <>
      <Swiper
        effect={'cube'}
        grabCursor={true}
        loop={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 50,
          shadowScale: 0.94,
        }}
        autoplay={{
          delay: 2900,
          disableOnInteraction: false,
        }}
        pagination={true}
        modules={[EffectCube, Pagination, Autoplay]}
        className="mySwiper"
      >
        {images?.map((img, key) =>
          <SwiperSlide key={key}>
            <img src={img} />
          </SwiperSlide>
        )}
      </Swiper>
    </>
  );
}
